.playerView {
  border-radius: 1em;
  border: 2px solid var(--primary-color);
  margin: 3em 0em 4em 0em;
  padding: 1em 2em;
  box-sizing: border-box !important;
}

.playerView_playerImage {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.playerView_placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.playerView_playerImage img {
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: cover;
  border-radius: 1em;
  aspect-ratio: 1/1;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.playerView_playerImage img.loaded {
  opacity: 1;
}

.playerView_playerImage img.loading {
  opacity: 0;
}

.playerView_playerName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1.25em 0;
}

.playerView_playerName h2 {
  margin: 0;
  padding-right: 0.4em;
  font-weight: 700 !important;
}

.playerView_playerDescription {
  padding: 1.25em 0;
}

.chipText {
  padding: 0.5em;
  border-radius: 20px;
  background-color: var(--primary-color);
  border: 2px solid var(--white-color);
  display: inline-block;
  margin: 0.5em 0.5em 0.5em 0em;
  font-weight: 700;
}

.chipText.red {
  background-color: var(--primary-color);
  border: 2px solid var(--white-color);
}

.chipText.white {
  background-color: var(--white-color);
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

@media (max-width: 1240px) {
  .playerView {
    width: 100%;
    flex-grow: 1 !important;
  }
}