.bannerPage {
  background: var(--secondary-color);
  color: var(--white-color);
}

.bannerPage_container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 5em 0em;
}

.bannerPage_container > h1 {
  margin: 0 !important;
  font-weight: 700 !important;
  font-size: 3.5em;
}

@media (max-width: 992px) {
  .bannerPage_container {
    padding: 2.5em 1.5em;
  }

  .bannerPage_container > h1 {
    font-size: 2.5em !important;
  }
}