body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000 !important;
  color: var(--white-color) !important;
}
/* 
.icono-close{
  display: none;
}

.visible-menu{
    left: 0 !important;
    opacity: 1 !important;
  z-index: 999 !important;
}

.nav-menu-hamburg{
    position: fixed;
    top: 0;
    bottom: 0;
    left:-800px; 
    width: 100%;
    z-index: -1;
    padding: 15px 15px 30px 30px;
    background: url('./assets/bg.png');
    opacity: 0;
    transition: all 0.75s ease;
    font-size: 1.2em;
    overflow-y: auto;
}
.nav-menu-hamburg a{
    color: white;
    font-weight: bold;
    display:block;
    padding: 10px;
    transition: all ease 0.25s;
  font-size: 20px !important;
}
 
.nav-menu-hamburg a:hover{
  color: rgba(255,255,255,1);
}

.nav-menu {
  text-align: left;
}

.bg_error{
  background-color: rgba(255,255,255,.4);
  border: 1px solid #707070;
  border-radius: 15px;
  height: 80vh;
  width: 85% !important;
  margin: auto !important;
}
 .video-inicio {
  border-radius: 20px;
  width: 100%;
  height: 612px;
} 

@media (min-width: 992px) {
  .menu-only-mobile{
    display: none !important;
  }

}

@media (max-width:768px) {
  .video-inicio{
    width: 512px !important;
    height: 288px !important;
  }
}


textarea:focus, input:focus{
  transition: all .3s ease-in-out;
  transform: scale(1.01);
  outline: none;
} */

